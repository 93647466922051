import { FC } from "react";

const EconomIcon: FC = () => {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.57333 0 0 3.57333 0 8C0 13.3333 8 21.3333 8 21.3333C8 21.3333 16 13.3333 16 8C16 3.57333 12.4267 0 8 0ZM8 2.66667C9.41449 2.66667 10.771 3.22857 11.7712 4.22876C12.7714 5.22896 13.3333 6.58551 13.3333 8C13.3333 10.96 10.96 13.3333 8 13.3333C6.58551 13.3333 5.22896 12.7714 4.22876 11.7712C3.22857 10.771 2.66667 9.41449 2.66667 8C2.66667 6.58551 3.22857 5.22896 4.22876 4.22876C5.22896 3.22857 6.58551 2.66667 8 2.66667Z"
        fill="#0E874D"
      />
      <circle cx="8" cy="8" r="3" fill="#0E874D" />
    </svg>
  );
};

export default EconomIcon;
