import { Box, Button, ButtonProps, styled } from "@mui/material";

type MarketplacesType = "wb" | "ozon" | "ya" | "mega" | "main";

const MARKERPLACES: Array<{
  label: string;
  value: MarketplacesType;
  isVisible?: boolean;
}> = [
  {
    label: "Wildberries",
    value: "wb",
    isVisible: true,
  },
  {
    label: "Ozon",
    value: "ozon",
  },
  {
    label: "Yandex",
    value: "ya",
  },
  {
    label: "Мегамаркет",
    value: "mega",
  },
  {
    label: "Общий",
    value: "main",
  },
];

const BUTTON_COLORS: Record<MarketplacesType, string> = {
  wb: "#8E04A9",
  ozon: "0C60FF",
  ya: "FF4113",
  mega: "6A33B0",
  main: "0E874D",
};

const BUTTON_BACKGROUND: Record<MarketplacesType, string> = {
  wb: "#F6E2F9",
  ozon: "E6EEFD",
  ya: "FFE4DE",
  mega: "EEE1FF",
  main: "DDF1E2",
};

interface StyledButtonProps extends ButtonProps {
  view: MarketplacesType;
}

const StyledButton = styled(Button)<StyledButtonProps>(({ view }) => ({
  fontWeight: 600,
  color: BUTTON_COLORS[view],
  backgroundColor: BUTTON_BACKGROUND[view],
  height: "31px",
  "&:hover, &:active": {
    color: BUTTON_COLORS[view],
    backgroundColor: BUTTON_BACKGROUND[view],
  },
}));

const Marketplaces = () => {
  return (
    <Box sx={{ ml: 3 }}>
      {MARKERPLACES.map(
        (item) =>
          item.isVisible && (
            <StyledButton
              variant="contained"
              view={item.value}
              key={item.value}
            >
              {item.label}
            </StyledButton>
          )
      )}
    </Box>
  );
};

export default Marketplaces;
