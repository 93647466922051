import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@emotion/react";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CssBaseline, createTheme } from "@mui/material";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import Registration from "./pages/registration";
import RequestFactory, { IResponse } from "./request/request-factory";
import RequestConfigList from "./request/RequestConfigList";
import { CoreContext } from "./CoreContext";
import { StoreContextProvider } from "./StoreContext";
import { RootStore } from "./store/RootStore";
import RouteView from "./RouteView";
import { IS_ADMIN_STORAGE_NAME, TOKEN_STORAGE_NAME } from "./constants";
import Notifier from "./Notifier";
import PopupProvider from "./PopupProvider";
import "./index.css";
import AdminMain from "./pages/admin-main";
import AdminRouteView from "./AdminRouteView";

const root = ReactDOM.createRoot(
  document.getElementById("root") as ReactDOM.Container
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#56AA82",
      light: "#E3F1E6",
      contrastText: "#fff",
    },
  },
});

const store = new RootStore();

const onError = (res: IResponse<any>): void => {
  if (res.status === "401") {
    store.userStore.setAuthorized(false);
    store.notificationStore.addNotification({
      text: "Ошибка авторизации! Попробуйте войти снова ",
      type: "error",
    });
  } else {
    store.notificationStore.addNotification({
      text: res.message || "Произошла ошибка",
      type: "error",
    });
  }
};

const requestFactory = new RequestFactory({
  requestConfigList: RequestConfigList,
  onError,
});
const createRequest = requestFactory.createRequest.bind(requestFactory);
store.setCreateRequest(createRequest);

if (localStorage.getItem(TOKEN_STORAGE_NAME)) {
  store.userStore.setAuthorized(true);
}

const isAdmin = localStorage.getItem(IS_ADMIN_STORAGE_NAME);
if (isAdmin) {
  store.userStore.setIsAdmin(Boolean(isAdmin === "true"));
}

root.render(
  <React.StrictMode>
    <SnackbarProvider autoHideDuration={5000} preventDuplicate>
      <StoreContextProvider value={store}>
        <CoreContext.Provider value={{ createRequest }}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Notifier />
            <Router>
              <PopupProvider />
              <Switch>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/demo">
                  <Login isDemo />
                </Route>
                <Route exact path="/registration">
                  <Registration />
                </Route>
                <RouteView exact path="/">
                  <Dashboard />
                </RouteView>
                <RouteView exact path="/dashboard/:id">
                  <Dashboard />
                </RouteView>
                <AdminRouteView exact path="/admin">
                  <AdminMain />
                </AdminRouteView>
              </Switch>
            </Router>
          </ThemeProvider>
        </CoreContext.Provider>
      </StoreContextProvider>
    </SnackbarProvider>
  </React.StrictMode>
);
