import React, { FC, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { Box } from "@mui/material";

import useStore from "./hooks/useStore";
import Loader from "./components/loader/Loader";
import AppBar from "./components/app-bar/app-bar";

export interface IRouteProps {
  children: React.ReactNode;
  [option: string]: any;
}

const RouteView: FC<IRouteProps> = ({
  children,
  ...rest
}: IRouteProps): JSX.Element => {
  const { userStore, isLoading, adminStore } = useStore();
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  if (!userStore.isAuthorized) {
    return <Redirect to="/login" />;
  }

  return (
    <Route {...rest}>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          display: "flex",
          backgroundColor: (theme) => theme.palette.grey[100],
          color: "#ccc",
        }}
      >
        <AppBar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: "#ccc",
            mt: 8,
            p: 3,
            flexGrow: 1,
            height: "calc(100vh - 64px)",
            overflow: "auto",
          }}
        >
          {children}
        </Box>
      </Box>
    </Route>
  );
};

export default observer(RouteView);
