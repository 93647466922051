import { FC } from "react";

const SuppliesIcon: FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.819 6.09029H10.4551C9.24774 6.09029 8.27314 7.06489 8.27314 8.27223V13.3634C8.27314 14.5708 9.24774 15.5454 10.4551 15.5454L9.72777 16.2727V17H10.4551L11.9097 15.5236H13.3643L14.819 17H15.5463V16.2727L14.819 15.5454C16.0263 15.5454 17.0009 14.5708 17.0009 13.3634V8.27223C17.0009 7.06489 16.0263 6.09029 14.819 6.09029ZM10.4551 14.0907C10.0551 14.0907 9.72777 13.7635 9.72777 13.3634C9.72777 12.9634 10.0551 12.6361 10.4551 12.6361C10.8551 12.6361 11.1824 12.9634 11.1824 13.3634C11.1824 13.7635 10.8551 14.0907 10.4551 14.0907ZM14.819 14.0907C14.4189 14.0907 14.0917 13.7635 14.0917 13.3634C14.0917 12.9634 14.4189 12.6361 14.819 12.6361C15.219 12.6361 15.5463 12.9634 15.5463 13.3634C15.5463 13.7635 15.219 14.0907 14.819 14.0907ZM15.5463 11.9088H9.72777V8.41042H15.5463V11.9088ZM5.1166 10.8396L5.36388 12.6361L4.6002 13.4071L3.32013 11.0942L1 9.80686L1.77095 9.02136L3.58924 9.29047L6.40394 6.47577L1 3.63197L2.03279 2.60645L8.7168 4.14836L11.5461 1.31911C11.9534 0.89363 12.6807 0.89363 13.088 1.31911C13.5171 1.74822 13.5171 2.45372 13.088 2.86101L10.586 5.36298H10.4551C8.84772 5.36298 7.54583 6.66487 7.54583 8.27223V8.41042L5.1166 10.8396Z"
        fill="#0E874D"
      />
      <path
        d="M10.4551 14.0907C10.0551 14.0907 9.72777 13.7635 9.72777 13.3634C9.72777 12.9634 10.0551 12.6361 10.4551 12.6361C10.8551 12.6361 11.1824 12.9634 11.1824 13.3634C11.1824 13.7635 10.8551 14.0907 10.4551 14.0907Z"
        fill="#0E874D"
      />
      <path
        d="M14.819 14.0907C14.4189 14.0907 14.0917 13.7635 14.0917 13.3634C14.0917 12.9634 14.4189 12.6361 14.819 12.6361C15.219 12.6361 15.5463 12.9634 15.5463 13.3634C15.5463 13.7635 15.219 14.0907 14.819 14.0907Z"
        fill="#0E874D"
      />
      <path
        d="M14.819 6.09029H10.4551C9.24774 6.09029 8.27314 7.06489 8.27314 8.27223V13.3634C8.27314 14.5708 9.24774 15.5454 10.4551 15.5454L9.72777 16.2727V17H10.4551L11.9097 15.5236H13.3643L14.819 17H15.5463V16.2727L14.819 15.5454C16.0263 15.5454 17.0009 14.5708 17.0009 13.3634V8.27223C17.0009 7.06489 16.0263 6.09029 14.819 6.09029ZM10.4551 14.0907C10.0551 14.0907 9.72777 13.7635 9.72777 13.3634C9.72777 12.9634 10.0551 12.6361 10.4551 12.6361C10.8551 12.6361 11.1824 12.9634 11.1824 13.3634C11.1824 13.7635 10.8551 14.0907 10.4551 14.0907ZM14.819 14.0907C14.4189 14.0907 14.0917 13.7635 14.0917 13.3634C14.0917 12.9634 14.4189 12.6361 14.819 12.6361C15.219 12.6361 15.5463 12.9634 15.5463 13.3634C15.5463 13.7635 15.219 14.0907 14.819 14.0907ZM15.5463 11.9088H9.72777V8.41042H15.5463V11.9088ZM5.1166 10.8396L5.36388 12.6361L4.6002 13.4071L3.32013 11.0942L1 9.80686L1.77095 9.02136L3.58924 9.29047L6.40394 6.47577L1 3.63197L2.03279 2.60645L8.7168 4.14836L11.5461 1.31911C11.9534 0.89363 12.6807 0.89363 13.088 1.31911C13.5171 1.74822 13.5171 2.45372 13.088 2.86101L10.586 5.36298H10.4551C8.84772 5.36298 7.54583 6.66487 7.54583 8.27223V8.41042L5.1166 10.8396Z"
        stroke="#0E874D"
        strokeWidth="0.8"
      />
      <path
        d="M10.4551 14.0907C10.0551 14.0907 9.72777 13.7635 9.72777 13.3634C9.72777 12.9634 10.0551 12.6361 10.4551 12.6361C10.8551 12.6361 11.1824 12.9634 11.1824 13.3634C11.1824 13.7635 10.8551 14.0907 10.4551 14.0907Z"
        stroke="#0E874D"
        strokeWidth="0.8"
      />
      <path
        d="M14.819 14.0907C14.4189 14.0907 14.0917 13.7635 14.0917 13.3634C14.0917 12.9634 14.4189 12.6361 14.819 12.6361C15.219 12.6361 15.5463 12.9634 15.5463 13.3634C15.5463 13.7635 15.219 14.0907 14.819 14.0907Z"
        stroke="#0E874D"
        strokeWidth="0.8"
      />
    </svg>
  );
};

export default SuppliesIcon;
