import { FC } from "react";

const AnalyticsIcon: FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0L16 0.5C16 0.5 15.2621 3.62147 15 4.5L14 3L8.5 6.34224C8.5 6.34224 6.56306 4.0132 5.87137 3.20456L0.835645 6.125L0.417822 5.3125L0 4.5C1.3666 3.71747 6.2731 1.16451 6.2731 1.16451C6.2731 1.16451 8.16926 3.19278 8.85954 4L13 1.5L12 0ZM4.07949 6.61299C4.07949 6.45939 3.91433 6.3622 3.78034 6.4373C2.55228 7.12553 0 8.5 0 8.5C0 8.5 0.0222415 14.9148 0.000168085 17C0.000168085 17 3.21148 17 4.07949 17V6.61299ZM5.60216 17H10.0703V9.02554C10.0703 8.87689 9.91388 8.78019 9.7809 8.84664L8.06159 9.70577C7.65265 8.99013 6.54882 7.26468 5.96958 6.36508C5.86175 6.19761 5.60333 6.27457 5.60331 6.47375L5.60216 17ZM11.593 17V7.94069L15.7031 5.66443C15.8364 5.5906 16 5.68664 16 5.83902C16 6.2398 16 6.86912 16 7C16 10.9052 16 17 16 17C16 17 12.5821 17 11.593 17Z"
        fill="#0E874D"
      />
    </svg>
  );
};

export default AnalyticsIcon;
