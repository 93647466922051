import { FC } from "react";

const VoronkaIcon: FC = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.88265 6.99573C7.14632 6.96856 10.9361 6.9837 10.9998 7.0027C10.9826 7.15438 10.9915 7.31596 10.9916 7.46901L10.993 8.29584L10.9937 13.6728C10.9938 13.7891 10.9815 14.416 10.9815 14.416L6.8856 14.4733V9.05263L6.88725 7.76949C6.88763 7.51208 6.89476 7.25281 6.88265 6.99573Z"
        fill="#0E874D"
      />
      <path
        d="M0.964562 0.816487C0.703543 0.48717 0.938125 8.42827e-05 1.35834 0.000140893L16.462 0.00217563C16.8801 0.00223195 17.1146 0.484897 16.8569 0.814098C14.5811 3.72096 10.9885 8.44121 10.9885 8.44121L6.87135 8.43424C6.87135 8.43424 3.16179 3.58865 0.964562 0.816487Z"
        fill="#0E874D"
      />
    </svg>
  );
};

export default VoronkaIcon;
