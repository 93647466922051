import React, { FC } from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import { ChangeHandler, FieldError, UseFormRegister } from "react-hook-form";
import { InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";

interface IMuiFormSelectProps {
  name: string;
  value?: string;
  onChange?: ChangeHandler;
  label: string;
  options: Array<{ value: string; label: string }>;
  error?: FieldError;
  register: UseFormRegister<any>;
}

const MuiFormSelect: FC<IMuiFormSelectProps> = ({
  options,
  label,
  name,
  error,
  onChange,
  value = undefined,
  register,
}) => {
  return (
    <FormControl error={Boolean(error?.message)} sx={{ width: "100%" }}>
      {label && (
        <InputLabel id={`${name}-label`} variant="outlined">
          {label}
        </InputLabel>
      )}
      <Select
        MenuProps={{ variant: "menu" }}
        variant="outlined"
        id={name}
        input={<OutlinedInput label={label} />}
        labelId={`${name}-label`}
        {...register(name)}
        // value={value}
        // onChange={onChange}
      >
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error?.message && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default MuiFormSelect;
