import { FC } from "react";

const DdcIcon: FC = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3334 9.33329C10.0325 9.33329 8.16669 8.39996 8.16669 7.24996C8.16669 6.09996 10.0325 5.16663 12.3334 5.16663C14.6342 5.16663 16.5 6.09996 16.5 7.24996C16.5 8.39996 14.6342 9.33329 12.3334 9.33329Z"
        fill="#0E874D"
      />
      <path
        d="M8.16669 10.7831C8.16669 11.9331 10.1992 13.0001 12.5 13.0001C14.8009 13.0001 16.5 11.7333 16.5 10.5833M1.5 6.50006C1.5 7.65006 3.19917 8.50006 5.5 8.50006C6.43833 8.50006 7.47086 8.26173 8.16669 8.00006M1.5 9.50006C1.5 10.6501 3.19917 11.5001 5.5 11.5001C6.43833 11.5001 7.30333 11.2617 8 11.0001M12.3334 9.33329C10.0325 9.33329 8.16669 8.39996 8.16669 7.24996C8.16669 6.09996 10.0325 5.16663 12.3334 5.16663C14.6342 5.16663 16.5 6.09996 16.5 7.24996C16.5 8.39996 14.6342 9.33329 12.3334 9.33329Z"
        stroke="#0E874D"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 3.0834V12.2501C1.5 13.4001 3.36583 14.3334 5.66667 14.3334C6.605 14.3334 7.47 14.1784 8.16667 13.9167M8.16667 13.9167V7.25006M8.16667 13.9167C8.16667 15.0667 10.0325 16.0001 12.3333 16.0001C14.6342 16.0001 16.5 15.0667 16.5 13.9167V7.25006M9.83333 5.5834V3.0834"
        stroke="#0E874D"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66667 5.16667C3.36583 5.16667 1.5 4.23333 1.5 3.08333C1.5 1.93333 3.36583 1 5.66667 1C7.9675 1 9.83333 1.93333 9.83333 3.08333C9.83333 4.23333 7.9675 5.16667 5.66667 5.16667Z"
        fill="#0E874D"
        stroke="#0E874D"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DdcIcon;
