import { FC } from "react";

const EconomIcon: FC = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.39436 14.2472C2.55808 14.2397 2.72484 14.246 2.88886 14.2461L3.8437 14.2464L6.73756 14.2456L15.6786 14.2464L15.6825 15.1776L15.5934 15.1802L2.29856 15.1747C2.29887 15.0456 2.27544 14.372 2.31375 14.3053C2.33344 14.2711 2.35913 14.2601 2.39436 14.2472Z"
        fill="#0E874D"
        stroke="#0E874D"
        strokeWidth="0.4"
      />
      <path
        d="M1.40063 15.3012L16.5523 15.2979C16.5579 15.6168 16.5539 15.9365 16.5517 16.2555C15.9398 16.2621 15.3271 16.2549 14.7151 16.2533L12.5976 16.2546L1.40107 16.2582L1.40063 15.3012Z"
        fill="#0E874D"
        stroke="#0E874D"
        strokeWidth="0.4"
      />
      <path
        d="M12.3424 7.16027L14.5312 7.15967C14.5515 8.18003 14.5331 9.20374 14.533 10.2244C14.5328 11.5152 14.5463 12.807 14.5318 14.0976L12.342 14.0993L12.3424 7.16027Z"
        fill="#0E874D"
        stroke="#0E874D"
        strokeWidth="0.4"
      />
      <path
        d="M3.43295 7.1649L5.65415 7.16357L5.65302 14.0974C5.39256 14.1062 5.1301 14.0996 4.86945 14.0996L3.4228 14.0994L3.42331 8.73092C3.42335 8.52165 3.40628 7.24029 3.43295 7.1649Z"
        fill="#0E874D"
        stroke="#0E874D"
        strokeWidth="0.4"
      />
      <path
        d="M7.84497 7.16005L10.0834 7.1571L10.0832 11.7619L10.0825 13.252C10.0823 13.5338 10.0868 13.8164 10.079 14.098C9.82059 14.104 9.56112 14.0991 9.30262 14.099L7.8461 14.0987L7.84497 7.16005Z"
        fill="#0E874D"
        stroke="#0E874D"
        strokeWidth="0.4"
      />
      <path
        d="M8.95272 1C9.02891 1.03556 9.10293 1.10989 9.17194 1.15955C9.30833 1.25769 9.4478 1.35196 9.58424 1.45042L11.1347 2.56582C12.1085 3.26804 13.0779 3.97642 14.0428 4.69096L16.0395 6.14811C16.3105 6.34653 16.5855 6.5403 16.8541 6.74196C16.9023 6.77817 16.9568 6.8193 16.9805 6.87616C16.9746 6.90993 16.9633 6.9376 16.9386 6.96229C16.8889 7.012 16.8135 7.03138 16.7456 7.03653C16.6434 7.04429 16.538 7.03874 16.4355 7.03792C16.2604 7.03616 16.0853 7.03542 15.9102 7.03569C15.3049 7.03957 14.6995 7.0394 14.0942 7.0352L9.02365 7.02948L1.28493 7.04174C1.20062 7.04136 1.10367 7.03453 1.04261 6.9686C1.01584 6.93969 0.996067 6.8951 1.00067 6.85513C1.00437 6.82291 1.03511 6.79965 1.05833 6.77958C1.18704 6.66833 1.33738 6.57422 1.47502 6.47393L2.25644 5.90126L8.95272 1Z"
        fill="#0E874D"
        stroke="#0E874D"
        strokeWidth="0.4"
      />
    </svg>
  );
};

export default EconomIcon;
