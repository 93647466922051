import { FC } from "react";

const AttentionIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#0E874D" />
      <path
        d="M9.63242 12C9.63242 12.8248 8.96383 13.4934 8.13909 13.4934C7.31434 13.4934 6.64575 12.8248 6.64575 12C6.64575 11.1753 7.31434 10.5067 8.13909 10.5067C8.96383 10.5067 9.63242 11.1753 9.63242 12Z"
        fill="white"
      />
      <path
        d="M6.31494 3H9.68573L9.28123 9H6.76438L6.31494 3Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AttentionIcon;
