import { FC } from "react";

const ArrowRightIcon: FC = () => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2L5.49998 5.50002L2 9"
        stroke="#8EC6AB"
        strokeWidth="1.94491"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M6.98926 2L10.4892 5.50002L6.98926 9"
        stroke="#8EC6AB"
        strokeWidth="1.94491"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default ArrowRightIcon;
