import React, { FC, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { Box, IconButton } from "@mui/material";

import useStore from "./hooks/useStore";
import Loader from "./components/loader/Loader";
import AppBar from "./components/app-bar/app-bar";
import Navigation from "./components/navigation/navigation";
import ArrowLeftIcon from "./components/icons/arrow-left-icon";
import ArrowRightIcon from "./components/icons/arrow-right-icon";

export interface IRouteProps {
  children: React.ReactNode;
  [option: string]: any;
}

const RouteView: FC<IRouteProps> = ({
  children,
  ...rest
}: IRouteProps): JSX.Element => {
  const { userStore, isLoading, reportsStore } = useStore();
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (userStore.isAuthorized) {
      reportsStore.getReports();
    }
  }, [userStore.isAuthorized]);

  if (!userStore.isAuthorized) {
    return <Redirect to="/login" />;
  }

  if (userStore.isAdmin) {
    return <Redirect to="/admin" />;
  }

  return (
    <Route {...rest}>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          display: "flex",
          backgroundColor: (theme) => theme.palette.grey[100],
        }}
      >
        <AppBar open={open} toggleDrawer={toggleDrawer} />
        <Navigation open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: "white",
            mt: 8,
            flexGrow: 1,
            height: "calc(100vh - 64px)",
            overflow: "auto",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              pt: 3,
              pl: 3,
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <IconButton
              onClick={toggleDrawer}
              sx={{ position: "absolute", left: "9px", top: "9px" }}
              size="small"
              disableRipple
              disableFocusRipple
            >
              {open ? <ArrowLeftIcon /> : <ArrowRightIcon />}
            </IconButton>
            {children}
          </Box>
        </Box>
      </Box>
    </Route>
  );
};

export default observer(RouteView);
