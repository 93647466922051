import { FC } from "react";

import {
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import LogoutIcon from "@mui/icons-material/Logout";
import TelegramIcon from "@mui/icons-material/Telegram";

import logo from "./logo.png";
import Marketplaces from "../marketplaces/marketplaces";
import useStore from "../../hooks/useStore";
import { observer } from "mobx-react-lite";

interface IPropsAppBar {
  open: boolean;
  toggleDrawer: () => void;
}

const AppBar: FC<IPropsAppBar> = () => {
  const {
    userStore: { logout, isAdmin, user },
  } = useStore();

  return (
    <MuiAppBar
      position="absolute"
      sx={{
        zIndex: 10000,
        backgroundColor: (theme) =>
          isAdmin ? theme.palette.primary.main : theme.palette.grey[100],
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          paddingLeft: "10px !important",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="Logo" style={{ width: "200px" }} />

        <Stack
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          flexDirection="row"
        >
          {!isAdmin && <Marketplaces />}
          {isAdmin && (
            <Typography ml={3} variant="h5">
              Админка
            </Typography>
          )}

          <Box>
            {!isAdmin && (
              <Button
                variant="contained"
                size="small"
                startIcon={<TelegramIcon />}
                href="https://t.me/effstepsalesdir"
                target="_blank"
                sx={{ mr: 1 }}
              >
                Техподдержка
              </Button>
            )}
            {!isAdmin && (
              <Button
                variant="contained"
                size="small"
                href="https://t.me/effstepsalesdir"
                target="_blank"
                sx={{ mr: 1 }}
              >
                Хотелки
              </Button>
            )}

            <IconButton
              size="large"
              color={isAdmin ? "inherit" : "primary"}
              onClick={() => logout()}
            >
              <LogoutIcon />
            </IconButton>
          </Box>
        </Stack>
      </Toolbar>
    </MuiAppBar>
  );
};

export default observer(AppBar);
