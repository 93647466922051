import React, { FC } from "react";
import { Backdrop, CircularProgress } from "@mui/material";

interface IProps {
  isLoading: boolean;
}

const Loader: FC<IProps> = ({ isLoading }: IProps): JSX.Element => {
  return (
    <Backdrop
      open={isLoading}
      sx={{
        zIndex: 50,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: "rgba(255, 255, 255, 0.7)",
      }}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default Loader;
