import { FC } from "react";

const EconomIcon: FC = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 14H2V9H4V10H6V9H8V14Z" fill="#0E874D" />
      <path d="M16 14H10V9H12V10H14V9H16V14Z" fill="#0E874D" />
      <path d="M6 8V2H8V3H10V2H12V8H6Z" fill="#0E874D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 18H1V16H17V18H14V17H4V18Z"
        fill="#0E874D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 8V1H5V8H1V15H17V8H13ZM2 14H8V9H6V10H4V9H2V14ZM6 2V8H12V2H10V3H8V2H6ZM10 14H16V9H14V10H12V9H10V14Z"
        fill="#0E874D"
      />
      <path
        d="M8 14H2V9H4V10H6V9H8V14Z"
        stroke="#0E874D"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M16 14H10V9H12V10H14V9H16V14Z"
        stroke="#0E874D"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        d="M6 8V2H8V3H10V2H12V8H6Z"
        stroke="#0E874D"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 18H1V16H17V18H14V17H4V18Z"
        stroke="#0E874D"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 8V1H5V8H1V15H17V8H13ZM2 14H8V9H6V10H4V9H2V14ZM6 2V8H12V2H10V3H8V2H6ZM10 14H16V9H14V10H12V9H10V14Z"
        stroke="#0E874D"
        strokeWidth="0.5"
        strokeLinejoin="round"
      />
      <path d="M7.5 2H10.5V3H7.5V2Z" fill="white" />
      <path d="M8.5 8H9.5V15H8.5V8Z" fill="white" />
      <rect x="12" y="9" width="3" height="1" fill="white" />
      <rect x="3" y="9" width="3" height="1" fill="white" />
    </svg>
  );
};

export default EconomIcon;
