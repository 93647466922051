import { ReactElement } from "react";

import AbcIcon from "../icons/abc-icon";
import AnalyticsIcon from "../icons/analytics-icon";
import AttentionIcon from "../icons/attention-icon";
import ComparisonIcon from "../icons/comparison-icon";
import CompetitorsIcon from "../icons/competitors-icon";
import DdcIcon from "../icons/ddc-icon";
import EconomIcon from "../icons/econom-icon";
import ExpectedMarginIcon from "../icons/expected-margin-icon";
import GrafIcon from "../icons/graf-icon";
import HeatmapIcon from "../icons/heatmap-icon";
import OtherIcon from "../icons/other-icon";
import PnlIcon from "../icons/pnl-icon";
import RisksIcon from "../icons/risks-icon";
import RnpIcon from "../icons/rnp-icon";
import SspIcon from "../icons/ssp-icon";
import SuppliesIcon from "../icons/supplies-icon";
import UnitEconomIcon from "../icons/unit-econom-icon";
import VoronkaIcon from "../icons/voronka-icon";
import WarehouseIcon from "../icons/warehouse-icon";

export const NavigationItemsIcons: Record<string, ReactElement> = {
  abc: <AbcIcon />,
  analytics: <AnalyticsIcon />,
  attention: <AttentionIcon />,
  comparison: <ComparisonIcon />,
  competitors: <CompetitorsIcon />,
  ddc: <DdcIcon />,
  econom: <EconomIcon />,
  expected_margin: <ExpectedMarginIcon />,
  graf: <GrafIcon />,
  heatmap: <HeatmapIcon />,
  other: <OtherIcon />,
  pnl: <PnlIcon />,
  risks: <RisksIcon />,
  rnp: <RnpIcon />,
  ssp: <SspIcon />,
  supplies: <SuppliesIcon />,
  unit_econom: <UnitEconomIcon />,
  voronka: <VoronkaIcon />,
  warehouse: <WarehouseIcon />,
};
