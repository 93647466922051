import { makeObservable, observable, action } from "mobx";
import RequestFactory from "../request/request-factory";

import UserStore from "./UserStore";
import ReportsStore from "./ReportsStore";
import NotificationStore from "./NotificationsStore";
import PopupStore from "./PopupStore";
import AdminStore from "./AdminStore";

export class RootStore {
  createRequest: RequestFactory["createRequest"] = Promise.resolve;

  isLoading: boolean = false;

  userStore: UserStore;

  reportsStore: ReportsStore;
  notificationStore: NotificationStore;
  popupStore: PopupStore;
  adminStore: AdminStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.reportsStore = new ReportsStore(this);
    this.notificationStore = new NotificationStore(this);
    this.popupStore = new PopupStore(this);
    this.adminStore = new AdminStore(this);

    makeObservable(this, { isLoading: observable, setLoading: action });
  }

  setCreateRequest(createRequest: RequestFactory["createRequest"]) {
    this.createRequest = createRequest;
  }

  public setLoading = (value: boolean) => {
    this.isLoading = value;
  };
}
