import { FC } from "react";

const RisksIcon: FC = () => {
  return (
    <svg
      width="17"
      height="24"
      viewBox="0 0 17 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25608 0C9.53396 0.00966599 9.8144 0.00215047 10.0925 0.00181773L11.5825 5.24507e-05L16.0364 0.000384988C15.8304 0.261584 15.606 0.510174 15.3897 0.763024L14.1525 2.21287L10.8747 6.11155C10.1102 7.00787 9.35427 7.91142 8.60699 8.82218L15.8849 8.82033C15.3693 9.34411 14.8124 9.83459 14.2801 10.342L10.9124 13.5468L4.44385 19.75C2.95438 21.1581 1.4731 22.5748 0 24C0.171403 23.6681 0.381937 23.3496 0.57391 23.0288C0.883808 22.514 1.19111 21.9977 1.49582 21.4799C1.62114 21.2704 1.7553 21.0662 1.87836 20.8554C2.94379 19.0284 4.01923 17.2073 5.10469 15.3921C5.48007 14.7502 5.86026 14.1111 6.24527 13.4749C5.46361 13.4638 4.68001 13.4764 3.89807 13.4759C2.87134 13.4716 1.84461 13.4729 0.817883 13.4797L7.26456 3.12529C7.91831 2.07709 8.58215 1.03533 9.25608 0Z"
        fill="#0E874D"
      />
    </svg>
  );
};

export default RisksIcon;
