import React, { useEffect } from "react";
import { Typography, Box, Stack, Button } from "@mui/material";
import { observer } from "mobx-react";
import PlusIcon from "@mui/icons-material/Add";
import useStore from "../hooks/useStore";
import UsersList from "../components/users-list/UsersList";
import UserDetailForm from "../components/user-detail-form/UserDetailForm";

const AdminMain = () => {
  const { adminStore, popupStore } = useStore();

  useEffect(() => {
    adminStore.getUsers();
  }, []);

  const onAdd = () => {
    popupStore.openPopup({
      props: { size: "md" },
      content: UserDetailForm,
    });
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" mb={2} color="black">
          Список юзеров
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PlusIcon />}
          component="label"
          onClick={onAdd}
        >
          Создать
        </Button>
      </Stack>
      <UsersList users={adminStore.users} />
    </Box>
  );
};

export default observer(AdminMain);
