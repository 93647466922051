import React, { FC } from "react";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import useStore from "../../hooks/useStore";
import { IUser } from "../../store/AdminStore";
import { yupResolver } from "@hookform/resolvers/yup";

interface IUserDetailFormProps {
  user?: IUser | null;
}

export type IUserDetailForm = Pick<
  IUser,
  "password" | "fio" | "is_blocked" | "login" | "email"
>;

const UserDetailForm: FC<IUserDetailFormProps> = ({ user }) => {
  const { adminStore, popupStore } = useStore();

  const schema = yup
    .object({
      fio: yup.string().required("Поле обязательно для заполнение"),
      password: yup
        .string()
        .required("Поле обязательно для заполнение")
        .min(6, "Минимум 6 символов"),
      login: yup.string().required("Поле обязательно для заполнение"),
      is_blocked: yup.bool().required(),
      email: yup
        .string()
        .required("Поле обязательно для заполнение")
        .email("Некорретный email"),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IUserDetailForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: user?.password || "",
      fio: user?.fio || "",
      is_blocked: user?.is_blocked || false,
      login: user?.login || "",
      email: user?.email || "",
    },
  });

  const onSubmit: SubmitHandler<IUserDetailForm> = (data) => {
    if (user) {
      adminStore
        .updateUser({ ...data, user_id: user?.user_id })
        .then(() => {
          popupStore.closePopup();
          adminStore.getUsers();
        })
        .catch(() => {});
    } else {
      adminStore
        .addUser(data)
        .then(() => {
          popupStore.closePopup();
          adminStore.getUsers();
        })
        .catch(() => {});
    }
  };

  const blocked = watch("is_blocked");

  return (
    <Box>
      <Typography variant="h6" mb={3}>
        {user ? "Редактирование юзера" : "Создание юзера"}
      </Typography>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 1 }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              error={!!errors.email}
              helperText={errors.email?.message}
              disabled={!!user}
              {...register("email")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              fullWidth
              id="fio"
              label="ФИО"
              autoFocus
              error={!!errors.fio}
              helperText={errors.fio?.message}
              {...register("fio")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              fullWidth
              id="login"
              label="Логин"
              autoFocus
              error={!!errors.login}
              helperText={errors.login?.message}
              {...register("login")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              fullWidth
              label="Пароль"
              id="password"
              error={!!errors.password}
              helperText={errors.password?.message}
              {...register("password")}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={blocked}
                  id="is_blocked"
                  {...register("is_blocked")}
                />
              }
              label="Заблокирован"
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Сохранить
        </Button>
      </Box>
    </Box>
  );
};

export default observer(UserDetailForm);
