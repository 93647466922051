import { FC } from "react";

const UnitEconomIcon: FC = () => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="12.5817" r="7" stroke="#0E874D" strokeWidth="2" />
      <path
        d="M16.028 5.22538L8.48706 11.598L8.60094 1.73173C10.4352 1.75282 11.747 1.98767 12.8624 2.52043C13.9723 3.05055 14.933 3.89723 16.028 5.22538Z"
        fill="#0E874D"
        stroke="#0E874D"
      />
    </svg>
  );
};

export default UnitEconomIcon;
